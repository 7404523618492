<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			 <el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="关键字" label-width="60px" prop="keyword">
				  <el-input v-model="queryForm.keyword" placeholder="请输入标题/名称" type="text" clearable style="width: 230px" />
				</el-form-item>
				<el-form-item>
				  <el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
				  <el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button>
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			 <el-table
				v-loading="loading"
				size="small"
				:data="list"
				element-loading-text="Loading"
				highlight-current-row
				style="width: 100%"
			 >
				<el-table-column label="ID" width="80" align="center">
				  <template slot-scope="scope">
					{{ scope.row.id }}
				  </template>
				</el-table-column>
				<el-table-column label="标题" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.title }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="参数名称" min-width="110" align="center">
				  <template slot-scope="scope">
					<span>{{ scope.row.name }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="参数类型" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.type_txt }}
				  </template>
				</el-table-column>
				<el-table-column label="参数值" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.value }}
				  </template>
				</el-table-column>
				<el-table-column label="要求格式" min-width="110" align="center">
				  <template slot-scope="scope">
					{{ scope.row.ext }}
				  </template>
				</el-table-column>
				<el-table-column label="状态" min-width="50" align="center">
				  <template slot-scope="scope">
					<el-tag v-if="scope.row.status === 2" size="mini" type="danger">停用</el-tag>
					<el-tag v-if="scope.row.status === 1" size="mini" type="success">正常</el-tag>
				  </template>
				</el-table-column>
				<el-table-column align="center" prop="utime" label="时间" min-width="120">
				  <template slot-scope="scope">
					<i class="el-icon-time" />
					<span>{{ scope.row.utime }}</span>
				  </template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
				  <template slot-scope="scope">
					<el-tooltip class="item" effect="dark" content="编辑" placement="top">
					  <el-button type="success" icon="el-icon-edit" size="mini" circle @click="handleEdit(scope.row)"></el-button>		
		            </el-tooltip>
					<el-tooltip class="item" effect="dark" content="删除" placement="top">
					  <el-button type="danger" icon="el-icon-delete" size="mini" circle @click="handleDelete(scope.row.id)"></el-button>		
					</el-tooltip>
				  </template>
				</el-table-column>
			 </el-table>
			 <pagination
				:total="total"
				auto-scroll
				:page.sync="queryForm.page"
				:limit.sync="queryForm.limit"
				@pagination="query"
			 />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="600px" :close-on-click-modal="false" append-to-body :title="title">
		  <el-form ref="form" :model="form" :rules="rules" label-width="80px" size="small">
		    <el-form-item label="标题" prop="title">
		      <el-input v-model="form.title" placeholder="请输入标题" type="text" clearable />
		    </el-form-item>
			<el-form-item label="参数名" prop="name">
			  <el-input v-model="form.name" placeholder="请输入参数名" type="text" clearable />
			  <small class="text-danger font-s">字母，如名称则可以填写name或者mingcheng</small>
			</el-form-item>
			<el-form-item label="类型" prop="type">
			  <el-select v-model="form.type" placeholder="请选择参数类型" style="width: 100%;">
				  <el-option v-for="(item,index) in types" :key="item.id" :value="item.id" :label="item.name"/>
			  </el-select>
			</el-form-item>
		    <el-form-item label="参数值" prop="value">
		      <el-input v-model="form.value" placeholder="请输入参数值" type="text" clearable />
			  <small class="text-danger font-s">若为下拉、单选、多选等类型，请设置列表值如：是|否，多个值用|隔开</small>
		    </el-form-item>
		    <el-form-item label="要求格式" prop="ext">
		      <el-input v-model="form.ext" placeholder="请输入要求格式" type="text" clearable />
			  <small class="text-danger font-s">不填则不限制，若为上传类型，如做格式要求可填写要求后缀如：jpg|png|jpeg</small>
		    </el-form-item>
		   <el-form-item  label="状态">
		      <el-radio-group v-model="form.status">
		      	<el-radio :label="1">使用</el-radio>
		      	<el-radio :label="2">禁用</el-radio>
		      </el-radio-group>
		    </el-form-item>
		  </el-form>
		  <div slot="footer" class="dialog-footer">
		    <el-button size="mini" @click="cancel">取 消</el-button>
		    <el-button type="primary" size="mini" @click="handleSubmit">确 定</el-button>
		  </div>
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject:['app'],
		mixins:[common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'template_params',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form:{
					title:'',
					name:'',
					type:'',
					value:'',
					status:1,
				},
				rules:{
					title: [
					  { required: true, message: '请输入标题', trigger: 'blur' }
					],
					name: [
					  { required: true, message: '请输入参数名称', trigger: ['blur', 'change'] }
					],
					type: [
					  { required: true, message: '请选择类型', trigger: ['blur', 'change'] }
					],
				},
				types:[
					{id:1,name:'文本框'},
					{id:2,name:'数字框'},
					{id:3,name:'日期'},
					{id:4,name:'日期时间'},
					{id:5,name:'下拉单选'},
					{id:6,name:'下拉多选'},
					{id:7,name:'复选单选'},
					{id:8,name:'复选多选'},
					{id:9,name:'单文件上传'},
					{id:10,name:'多文件上传'},
					{id:11,name:'单图片上传'},
					{id:12,name:'多图片上传'},
					{id:13,name:'文本域'},
					{id:14,name:'富文本'},
				],
			}
		},
		mounted() {
			
		},
		methods:{
			handleEdit(row) {
			  this.reset()
			  this.open = true
			  this.title = '编辑'
			  this.form = JSON.parse(JSON.stringify(row))
			  this.form.type = parseInt(this.form.type)
			},
		}
	}
</script>

<style>
</style>